<template>
  <div class="text-center">
    <my-table
      title=""
      :rows="branches"
      :columns="columns"
      :pagination="pagination"
      :is-loading="isLoading"
    >
      <template v-slot="{props}">
        <span v-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                @click.prevent="handleEditBranch(props.row)"
              >
                <feather-icon
                  icon="Edit2Icon"
                  class="mr-50"
                />
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click.prevent="deleteBranch(props.row)">
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                />
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
      </template>
    </my-table>
    <b-button
      variant="danger"
      :to="{ name: 'markets' }"
    >Back to Markets</b-button>
    <AddEditBranchModal
      :is-show="showAddNewBranchModal"
      :form="selectedBranch"
      :is-edit="true"
      @reset-modal="resetAddEditBranchModal"
      @refetch-branches="fetchBranches"
    />
  </div>
</template>

<script>
import MyTable from '@/components/MyTable.vue'
import AddEditBranchModal from '@/components/AddEditBranchModal.vue'
import axios from '@axios'

export default {
  name: 'MarketBranches',
  components: {
    MyTable,
    AddEditBranchModal,
  },
  props: {
    marketId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      branches: [],
      isLoading: false,
      showAddNewBranchModal: false,
      columns: [
        {
          label: 'ID',
          field: 'id',
          thClass: 'align-middle text-left',
          tdClass: 'align-middle text-nowrap text-left',
          type: 'number',
        },
        {
          label: 'Name',
          field: 'name',
          thClass: 'align-middle',
          tdClass: 'align-middle text-nowrap',
        },
        {
          label: 'State',
          field: 'state',
          thClass: 'align-middle',
          tdClass: 'align-middle text-nowrap',
        },
        {
          label: 'Branch',
          field: 'area',
          thClass: 'align-middle',
          tdClass: 'align-middle text-nowrap',
        },
        {
          label: 'Address',
          field: 'complete',
          thClass: 'align-middle',
          tdClass: 'align-middle',
        },
        {
          label: 'Phone',
          field: 'phone',
          thClass: 'align-middle',
          tdClass: 'align-middle text-nowrap',
        },
        {
          label: 'Action',
          field: 'action',
          thClass: 'align-middle',
          tdClass: 'align-middle text-nowrap',
          sortable: false,
        },
      ],
      pagination: {
        enabled: true,
        mode: 'records',
        perPage: 5,
        perPageDropdown: [5, 10, 15],
      },
      selectedBranch: {},
    }
  },
  created() {
    this.fetchBranches()
  },
  methods: {
    async fetchBranches() {
      this.isLoading = true
      const response = await axios.get(`client-branches/${this.marketId}`)
      if (response.status === 200) {
        this.branches = response.data
      }
      this.isLoading = false
    },
    handleEditBranch(row) {
      this.showAddNewBranchModal = true
      this.selectedBranch = {
        image_path: row.client.logo,
        client: row.client,
        branch: {
          client_id: row.client.id,
          id: row.id,
          address1: row.address1,
          address2: row.address2,
          address3: row.address3,
          unit: row.unit,
          postcode: row.postcode,
          state: row.state,
          country: row.country,
          area: row.area,
          phone: row.phone,
          name: row.name,
        },
      }
    },
    deleteBranch(row) {
      this.$swal({
        title: 'Are you sure to delete this branch?',
        text: row.name,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await axios.delete(`/client-branches/${row.id}`)
          if (response.status === 200) {
            this.fetchBranches()
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Successfully Deleted',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        }
      })
    },
    resetAddEditBranchModal() {
      this.showAddNewBranchModal = false
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
